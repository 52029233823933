@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300italic,400italic,700italic,400,300,700&amp;subset=all');

:root {
  --bg-color: #1f242d;
  --second-bg-color: #323946;
  --third-bg-color: #202324;
  --text-color: #e8e6e3;
  --main-color: #32c5d2;
  --alternate-main-color: #249aa5;
  --header-bg-color: #282b2d;
  --header-menu-border-color: #786f62;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  font-family: 'Roboto Condensed', sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
  background: lightgray;
}

::-webkit-scrollbar-track {
  background: var(--bg-color);
}

::-webkit-scrollbar-thumb {
  background: var(--second-bg-color);
}



html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  background: var(--header-bg-color);
  color: var(--text-color);

}

span {
  font-weight: bold;
}

a {
  text-decoration: none; 
  color: #337ab7;
}

.header {
  position: fixed;
  top: 20px;
  left: 1%;
  width: 98%;
  padding: 2rem 2%;
  background: var(--header-bg-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  z-index: 100;
}

.logo {
  font-size: 30px;
  max-height: 30px;
  color: var(--text-color);
  font-weight: 600;
  padding: 0px 1px;
}

.navbar a {
  font-size: 1.7rem;
  color: var(--text-color);
  margin-left: 4rem;
  transition: .3s;
  font-weight: 600;
}

.navbar a:hover,
.navbar a.active {
  color: var(--main-color);
}

#menu-icon {
  font-size: 3.6rem;
  color: var(--text-color);
  display: none;
}

.home .about {
  height: 100vh;
  color: var(--text-color);
  background-color: var(--third-color);
}

.home {
  background-image: url('assets/img/bg-102.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 101vh;
  display: flex;
  justify-content: center;
  align-items: center;

}

.home-content {
  width: 50%;
  height: 98%;
  flex: start;
  display: flex;
  margin-left: 5%;
  margin-top: 0 auto;
  margin-bottom: 0;
  justify-content: center;
  align-items: center;
}

.home-content-text {
  width: 100%;
}

.home-content h1 {
  font-size: 5.6rem;
  font-weight: 700;
  line-height: 1.3;
}

.home-content h3 {
  font-size: 3.2rem;
  font-weight: 700;
}

.home-content h3:nth-of-type(2) {
  margin-bottom: 2rem;
}

.home-content p {
  font-size: 1.6rem;
}

.home-img {
  flex: end;
  height: 98%;
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 5%;
  margin-top: 0 auto;
  margin-bottom: 0;
}

.home-img img {
  width: 100%;
}

.home span {
  color: var(--main-color);
  /* font-weight: bold; */
}

.home .social-media a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  background: transparent;
  border: .2rem solid var(--main-color);
  border-radius: 50%;
  font-size: 2rem;
  color: var(--main-color);
  margin: 3rem 1.5rem 3rem 0;
  transition: .5s ease;
}

.home .social-media a:hover {
  background: var(--main-color);
  color: var(--second-bg-color);
  box-shadow: 0 0 1rem var(--main-color);
}

.home .btn {
  display: inline-block;
  padding: 1rem 2.8rem;
  background: var(--main-color);
  border-radius: 4rem;
  box-shadow: 0 0 1rem var(--main-color);
  font-size: 1.6rem;
  color: var(--second-bg-color);
  letter-spacing: .1rem;
  font-weight: 600;
  transition: .5s ease;
}

.btn:hover {
  box-shadow: none;
}


/* ABOUT */

.about {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background: var(--header-bg-color);
  width: 100%;
  height: 80%;
  margin-top: -12px;

}

.heading {
  text-align: center;
  font-size: 4.5rem;
}

.about-content {
  width: 100%;
  height: 80%;
  padding: 100px 20%;
}

.about-content h2 {
  text-align: left;
  line-height: 1.2;
  font-size: 3.2rem;
  text-transform: uppercase;
  padding-top: 3rem;
}

.about-content h3 {
  font-size: 2.6rem;
  margin: 2rem 0 3rem;
}

.about-content p {
  font-size: 16px;
  margin: 2rem 0 3rem;
}

.about-content li {
  font-size: 1.6rem;
  list-style-position: inside;
  padding-left: 25px;
}

/* SERVICES */

.services {
  /* background-color: var(--header-bg-color); */
  /* height: 120vh; */
  margin-top: 100px;

}

.top-services {
  background-color: var(--header-bg-color);
  height: 100px;
}

.bottom-services {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 36px;
}

.bottom-services .box {
  /* border: solid 1px #ababab; */
  height: 250px;
  width: 250px;
  margin-top: -40px;
  text-align: center;
  display: inline-block;
}

.bottom-services .box .counter {
  border: solid 1px #fff;
  border-radius: 50%;
  margin: 50px;
  /* padding: 50px; */
  height: 150px;
  width: 150px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.bottom-services .box h3 {
  font-size: 24px;
  font-weight: 400;
  margin-top: -25px;
  text-transform: uppercase;
}

.parallax {
  /* The image used */
  background-image: url('assets/img/bg-38.jpg');
  /* Full height */
  height: 120vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax-content {
  margin-top: -120vh;
  display: flex;
  flex-wrap: wrap;
}

.parallax-content .box-left {
  height: 40vh;
  width: 45vw;
  background-color: var(--text-color);
  color: var(--bg-color);
  padding-left: 150px;
  padding-right: 100px;
  display: block;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.parallax-content .triangle-left {
  width: 0;
  height: 0;
  border-top: 20vh solid transparent;
  border-bottom: 20vh solid transparent;
  border-left: 20vh solid var(--text-color);
  color: var(--bg-color);
}

.parallax-content .box-right {
  flex: 1;
  height: 40vh;
  width: 47vw;
  background-color: var(--third-bg-color);
  padding-right: 150px;
  padding-left: 100px;
  display: block;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.parallax-content .triangle-right {
  width: 0;
  height: 0;
  border-top: 20vh solid transparent;
  border-bottom: 20vh solid transparent;
  border-right: 20vh solid var(--third-bg-color);
}

.parallax-content .next-row {
  flex-basis: 100%;
  height: 0;
}

.parallax-content .break {
  width: 45vw;
}

.services h3 {
  margin: 2rem 0 3rem;
  font-size: 2.6rem;
  font-weight: 600;
}

.services p {
  margin: 1rem 0 1.6rem;
  font-size: 2rem;
}

/* Prices */

.pricing {
  background-color: var(--text-color);
  min-height: 400px;
  margin-top: 100px;
  /* margin: 0 auto; */
  padding-top: 150px;
  color: var(--bg-color);
  text-align: center;
}

.pricing h3 {
  font-size: 2.6rem;
  font-weight: 600;
  margin: 2rem 0 3rem;
  text-transform: uppercase;
}

.pricing p {
  font-size: 2rem;
  font-weight: 400;
}

/* Testimonials */
.testimonials {
  background-color: #f7f7f7 !important;
  min-height: 550px;
  margin: 0 auto;
  padding-top: 120px;
  color: var(--bg-color);
  text-align: center;

}
.testimonials h3 {
  font-size: 2.6rem;
  font-weight: 600;
  margin: 2rem 0 3rem;
  text-transform: uppercase;
}

.testimonials p {
  font-size: 2rem;
  font-weight: 400;
}

.testimonials .t-boxes {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 120px;
  width: 90vw;
}

.testimonials .t-boxes .t-box {
  background-color: var(--text-color);
  display: inline;
  margin: 20px;
  padding: 30px 40px;
  width: 400px;
  height: 200px;
  text-align: justify;
}

.testimonials .t-boxes .t-box {
  position: relative;
}
.testimonials .t-boxes .t-box h4 {
  position: absolute;
  bottom: 0;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 10px;
  margin: 2.5rem 0 1.6rem;
  text-align: end;
  text-transform: uppercase;
}


/* Contact */

.contact {
  height: 700px;
  min-height: 300px;
  margin: 0 auto;
  padding: 50px;
  text-align: center;
}

.contact h2 {
  margin: 3rem;
}

.contact h3 {
  font-size: 2.6rem;
  font-weight: 600;
  margin: 3rem 0 3rem;
  text-transform: uppercase;
}

.contact form {
  max-width: 70rem;
  margin: 1rem auto;
  text-align: center;
  margin-bottom: 3rem;
}

.contact form .input-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: .7rem 0;
}

.contact form .input-box input,
.contact form textarea {
  width: 100%;
  padding: 1.5rem;
  font-size: 1.6rem;
  color: var(--text-color);
  background: var(--second-bg-color);
  border-radius: .8rem;
  margin: .7rem 0;
}

.contact form .input-box input {
  width: 49%;
}

.contact form textarea {
  resize: none;
}

.contact form .btn {
  background: var(--main-color);
  color: var(--text-color);
  margin-top: 2rem;
  cursor: pointer;
  font-size: 2.6rem;
  padding: 1rem;
  border-radius: 5px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem 9%;
  background: var(--second-bg-color);
  margin-top: -5.2vh;
}

.footer-text p {
  font-size: 1.6rem;
}

.footer-iconTop a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: .8rem;
  background: var(--main-color);
  border-radius: .8rem;
  transition: .5s ease;
}

.footer-iconTop a:hover {
  box-shadow: 0 0 1rem var(--main-color);
}

.footer-iconTop a i {
  font-size: 2.4rem;
  color: var(--second-bg-color);
}

.footer .c-socials {
  display: inline-flex;

}

.footer .c-socials img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

/* BREAKPOINTS */
@media (min-width: 992px) {
  html {
    font-size: 55%;
  }
}

@media (max-width: 991px) {
  .header {
    padding: 2rem 3%;
  }

  section {
    padding: 10rem 3%, 2rem;
  }

  .services {
    padding-bottom: 7rem;
  }

  .portfolio {
    padding-bottom: 7rem;
  }

  .footer {
    padding: 2rem 3%;
  }
}

@media (max-width: 768px) {
  #menu-icon {
    display: block;
  }

  .navbar {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 1rem 3%;
    background: var(--bg-color);
    border-top: .1rem solid rgba(0, 0, 0, .2);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .2);
    display: none;
  }

  .navbar.active {
    display: block;
  }

  .navbar a {
    display: block;
    font-size: 2rem;
    margin: 3rem 0;
  }

  .home {
    flex-direction: column;
  }

  .home-content {
    margin-top: 15%;
  }

  .home-content h3 {
    font-size: 1.6rem;
  }

  .home-content h1 {
    font-size: 2.5rem;
  }

  .home-img {
    filter: drop-shadow(0px 1vw 1vw rgba(0, 0, 0, 0.5));
    /* padding-top: 10%; */
    /* width: 100%; */
    height: auto;
    /* display: block; */
    width: 100vw;
    display: flex;
    /* justify-content: flex-end; */
    margin-top: auto;

  }

  .home-img img {
    width: 85vw;
    margin-bottom: 12px;
    /* align-self: flex-end; */
  }

  .about {
    flex-direction: column;
  }

  .hexagon-about img {
    padding-top: 10%;
    width: 100%;
    height: auto;
    display: block;
    filter: drop-shadow(0 1vw 1vw rgba(0, 0, 0, .5));
  }

  .about {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    background: var(--second-bg-color);

  }

  .about-img {
    filter: drop-shadow(0px 1vw 1vw rgba(0, 0, 0, .5));
    width: 60vw;
  }

  .about-img img {
    /* width: 35vw; */
    padding-top: 2%;
    width: 100%;
    height: auto;
    display: block;
    filter: drop-shadow(10px 5px 7px rgba(0, 0, 0, .5));
  }

  .services h2 {
    margin-bottom: 3rem;
  }

  .portfolio h2 {
    margin-bottom: 3rem;
  }

  .portfolio h4 {
    font-size: 2rem;
  }

  .portfolio-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer {
    flex-direction: column-reverse;
  }

  .footer p {
    text-align: center;
    margin-top: 2rem;
  }
}

@media (max-width: 617px) {
  .portfolio-container {
    grid-template-columns: 1fr;
  }

  .footer {
    flex-direction: column-reverse;
  }

  .footer p {
    text-align: center;
    margin-top: 2rem;
  }
}

@media (max-width: 450px) {
  html {
    font-size: 50%;
  }

  .contact form .input-box input {
    width: 100%;
  }

  .footer {
    flex-direction: column-reverse;
  }

  .footer p {
    text-align: center;
    margin-top: 2rem;
  }
}